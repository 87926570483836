<template>
    <modal ref="crearCategoria" :titulo="titulo" @guardar="insertCategoria">
        <ValidationObserver ref="validacion">
            <div class="row m-3">
                <div class="col">
                    <ValidationProvider v-slot="{errors}" rules="required" name="Categoría" class="d-flex flex-column">
                        <label class="ml-2">Categoría</label>
                        <el-input v-model="model.nombre" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row m-3">
                <div class="col text-right">
                    <slim-cropper ref="cropCategoria" :options="slimOptions" class="border" style="height:50px;width:400px;background:#F8F9FF;">
                        <div slot="label" class="">
                            <img src="/img/modales/camera.svg" alt="" />
                        </div>
                    </slim-cropper>
                    <small>Relación 8:1, Imagen de la categoría en la versión móvil</small>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Aws from '~/services/aws'
import Categorias from "../../../../services/configurar/productos";

export default {
    data(){
        return {
            model:{
                id: null,
                nombre: '',
                banner: ''
            },
            slimOptions: {
                ratio: '8:1',
                label:'Subir imagen',
                size: { width: 250, height: 250 },
                minSize: { width: 100, height: 100 },
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok'
            },
            titulo: '',
            editar: false,
        }
    },
    methods: {
        toggle(cat){
            if (cat){
                this.model.id = cat.id
                this.model.nombre = cat.nombre
                this.titulo = 'Editar categoría'
                this.editar = true
                if (cat.banner !== null){
                    this.$refs.cropCategoria.set_image(`https://${Aws.Bucket}.s3.amazonaws.com/${cat.banner}`)
                } else {
                    this.$refs.cropCategoria.instanciaCrop.remove()
                }
            } else {
                this.titulo = 'Crear categoría'
                this.editar = false
            }
            this.$refs.crearCategoria.toggle()
        },
        async insertCategoria(){
            try {

                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                const archivo = this.$refs.cropCategoria.instanciaCrop.dataBase64.output

                const url = await this.uploadCropperImage(archivo, 'categoria')
                this.model.banner = url
                this.model.tipo = 2
                this.model.id_tienda = this.$usuario.tienda.id
                if (this.editar){
                    const {data} = await Categorias.updateCategoria(this.model)
                    this.notificacion('Mensaje', 'Editado exitosamente', 'success')
                } else {
                    const {data} = await Categorias.insertCategoria(this.model)
                    this.notificacion('Mensaje', 'Creado exitosamente', 'success')
                }
                this.$emit('actualizar')
                this.model = {
                    id: null,
                    nombre: '',
                    banner: ''
                }
                this.$refs.cropCategoria.instanciaCrop.remove()

                this.$refs.crearCategoria.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>

<style lang="css" scoped>
</style>
